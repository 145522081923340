// useTranslations.js

import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";

export function useTranslations() {
    const page = usePage();
    const translationsRef = computed(() => page.props.translations['fr']);
    const translations = translationsRef.value;
    return { translations };
}
